import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';

declare var $: any;

@Component({
    selector: 'app-ast-select',
    templateUrl: './ast-select.component.html',
    styleUrls: ['./ast-select.component.scss']
})

export class AstSelectComponent implements OnInit {

    //<app-ast-select #astselect3 [rowWidth]="'rowwidth'" [hrPosition]="'hrPosition'" [selectionList]="templeMasterList" [listDetConf]="[{name:'templeName'},{name:'templeId',primary:true}]" (selection)="setSelectedEdiTemlpleValue($event)" [requiredFlag]="true"></app-ast-select>

    @Input() selectionList: any[] = [];
    @Input() listDetConf: any[] = [];
    @Input() rowWidth: any = 'auto';
    @Input() hrPosition: any = '0';
    @Input() varIndex: number;
    @Input() requiredFlag: boolean;
    @Input() disabledFlag: boolean;
    @Input() selectedValue: any;

    @Output() selection: EventEmitter<any> = new EventEmitter();

    @ViewChild('astSelect', { static: false }) input: ElementRef;
    @ViewChild('astSelectSearchBox', { static: false }) searchBox: ElementRef;
    @ViewChild('astdropdownItem', { static: false }) selectElement: ElementRef;

    searchValue: any;
    astselect: any;
    selectedObjectValue: any = null;

    searchFlag = true;
    focusFlag: boolean = true;
    clickFlag: boolean;

    constructor() { }

    ngOnInit() {
        $(".readonly").keydown(function (e: { which: number; preventDefault: () => void; }) {
            if (e.which != 9) {
                e.preventDefault();
            }
        });
    }

    // focus on search input box in click event
    selectBoxClick() {
        this.clickFlag = true;
        if (this.clickFlag) {
            this.innerInputFocus();
        }
        this.searchBox.nativeElement.focus();
    }

    // focus on search input box in focus event
    innerInputFocus() {
        if (!this.clickFlag) {
            this.open();
        }
        this.searchBox.nativeElement.focus();
    }

    // focus on search input box in keypress event
    setFocusWhenKeypress(event: any) {
        if (event.keyCode == 13 || event.keyCode == 40 || event.keyCode == 38) {
            this.searchBox.nativeElement.focus();
        }
    }

    // focus to dropdown
    setListFocus(event: any) {
        this.searchFlag = true;
        if (event.keyCode == 13 || event.keyCode == 40 || event.keyCode == 38) {
            this.selectElement.nativeElement.focus();
            this.focusFlag = true;
        }
    }

    resetSearchBox() {
        this.searchValue = "";
    }

    // open Drop down
    open() {
        this.input.nativeElement.click();
    }

    // dummy
    reset() {
        this.selectedValue = "";
        this.selectedObjectValue = null;
    }

    // dummy
    getSelectedvalue() {
        return this.selectedObjectValue;
    }

    //set index value of need variable
    selectVariableIndex(index: number) {
        if (index == undefined) {
            return this.varIndex = 0;
        } else {
            return this.varIndex = index;
        }
    }

    // selected value is set to the variable
    setSelectedValue(selected: any) {
        this.selectedObjectValue = selected;
        let i = this.selectVariableIndex(this.varIndex);
        this.selectedValue = selected[this.listDetConf[i].name];
        this.searchFlag = false;
        this.setSelectedListValue(selected);
        this.focusFlag = !this.focusFlag;
    }

    // get list for parent component
    getSelectedList(selectedArray: any) {
        this.selectionList = selectedArray;
    }

    // select value by primary id
    setSelectedValueById(selectedId: any) {
        let ci = this.listDetConf.findIndex(r => r.primary);
        ci = ci > -1 ? ci : 0;
        let selected = this.selectionList.find(r => r[this.listDetConf[ci].name] == selectedId);
        this.setSelectedValue(selected);
        this.open();
    }

    justFocus() {
        $('#astselect').focus();
    }

    // input blur
    funBlur() {
        if (!this.searchFlag) {
            if (this.selectedObjectValue && this.selectedObjectValue[this.listDetConf[0].name] != this.input.nativeElement.value) {
                this.selectedValue = "";
                this.selectedObjectValue = null;
            } else {
                if (!this.selectedObjectValue) {
                    this.selectedValue = "";
                    this.selectedObjectValue = null;
                }
            }
            this.input.nativeElement.click();
        }
    }

    setSelectedListValue(selected: any) {
        this.selection.emit(selected);
        this.input.nativeElement.focus();
        this.input.nativeElement.click();
    }

    // dropdown keyup
    keyUp(item: any, event: any) {
        if (event.keyCode == 13) {
            this.setSelectedValue(item);
            this.resetSearchBox();
        }
    }

    // dropdown click
    selectValueByClick(item: any) {
        this.selectedObjectValue = item;
        let i = this.selectVariableIndex(this.varIndex);
        this.selectedValue = item[this.listDetConf[i].name];
        this.setSelectedListValue(item);
        this.resetSearchBox();
    }

}
