import { Pipe, PipeTransform } from '@angular/core';
import { ProductAndRatePojo } from '../pojos/product-master.pojo';

declare var _: any;

@Pipe({
    name: 'productNameStock'
})

export class ProductNameAdministrativePipe implements PipeTransform {

    transform(array: any[], query: string, queryTwo: number): any {
        if (query) {
            let l = _.filter(array, (row: { productName: string; }) => row.productName.toLowerCase().indexOf(query.toLowerCase()) > -1);
            if (l != '') {
                return _.slice(l, 0, queryTwo);
            }
            return;
        }
        return _.slice(array, 0, 100);
    }

}

@Pipe({
    name: 'productNameStock'
})

export class ProductNameInStockReportPipe implements PipeTransform {

    transform(array: ProductAndRatePojo[], query: string, queryTwo: number): any {
        if (query) {
            let j = _.filter(array, (row: { product: { productCode: string; }; }) => (row.product.productCode + "").indexOf(query) > -1);
            if (j != '') {
                return _.slice(j, 0, queryTwo);
            } else {
                let l = _.filter(array, (row: { product: { productName: string; }; }) => row.product.productName.toLowerCase().indexOf(query.toLowerCase()) > -1);
                if (l != '')
                    return _.slice(l, 0, queryTwo);
            }
            return;
        }
        return _.slice(array, 0, 100);
    }

}

@Pipe({
    name: 'productNameMasterStock'
})

export class ProductNameMasterInStockReportPipe implements PipeTransform {

    transform(array: ProductAndRatePojo[], query: string, queryTwo: number): any {
        if (query) {
            let j = _.filter(array, (row: { productMasterPojo: { productCode: string; }; }) => (row.productMasterPojo.productCode + "").indexOf(query) > -1);
            if (j != '') {
                return _.slice(j, 0, queryTwo);
            } else {
                let l = _.filter(array, (row: { productMasterPojo: { productName: string; }; }) => row.productMasterPojo.productName.toLowerCase().indexOf(query.toLowerCase()) > -1);
                if (l != '')
                    return _.slice(l, 0, queryTwo);
            }
            return;
        }
        return _.slice(array, 0, 100);
    }

}


@Pipe({
    name: 'productTypeName'
})

export class ProductTypeNameInStockReportPipe implements PipeTransform {

    transform(array: any[], query: string, queryTwo: number): any {
        if (query) {
            let l = _.filter(array, (row: { productTypeName: string; }) => row.productTypeName.toLowerCase().indexOf(query.toLowerCase()) > -1);
            if (l != '') {
                return _.slice(l, 0, queryTwo);
            }
        }
        return _.slice(array, 0, 100);
    }

}
