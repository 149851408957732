import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-matrix-wave-loading',
    templateUrl: './matrix-wave-loading.component.html',
    styleUrls: ['./matrix-wave-loading.component.scss']
})

export class MatrixWaveLoadingComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}
