import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';

import { MatrixWaveLoadingComponent } from './matrix-wave-loading/matrix-wave-loading.component';
import { ProductMasterAndRatePipe, ProductMasterPipe } from '../master/product-master/product-master.pipe';
import { GenericUnitMasterPipe, GenericUnitNamePipe } from '../master/generic-unit-master/generic-unit-master.pipe';
import { IncentivemasterpipePipe, IncentivePipe } from '../master/incentive-master/incentivemasterpipe.pipe';
import { ProductNameInStockReportPipe, ProductNameMasterInStockReportPipe, ProductTypeNameInStockReportPipe } from '../pipes/product-name.pipe';
import { AstSelectComponent } from './ast-select/ast-select.component';
import { AstSelectPipe } from './ast-select/ast-select.pipe';
import { ManaulClosingStockPipe, ProductAndRateMasterListPipe } from '../pipes/product-details.pipe';

@NgModule({
    declarations: [
        MatrixWaveLoadingComponent,
        ProductMasterPipe,
        ProductNameInStockReportPipe,
        ProductNameMasterInStockReportPipe,
        ProductTypeNameInStockReportPipe,
        GenericUnitMasterPipe,
        GenericUnitNamePipe,
        IncentivemasterpipePipe,
        IncentivePipe,
        AstSelectComponent,
        AstSelectPipe,
        ProductMasterAndRatePipe,
        ProductAndRateMasterListPipe,
        ManaulClosingStockPipe
    ],
    imports: [
        CommonModule,
        FormsModule,
        HttpClientModule
    ],
    exports: [
        MatrixWaveLoadingComponent,
        ProductMasterPipe,
        ProductNameInStockReportPipe,
        ProductTypeNameInStockReportPipe,
        ProductNameMasterInStockReportPipe,
        GenericUnitMasterPipe,
        GenericUnitNamePipe,
        IncentivemasterpipePipe,
        IncentivePipe,
        AstSelectComponent,
        AstSelectPipe,
        FormsModule,
        HttpClientModule,
        ProductMasterAndRatePipe,
        ProductAndRateMasterListPipe,
        ManaulClosingStockPipe
    ]
})

export class SharedModuleModule { }
