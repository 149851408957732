import { Pipe, PipeTransform } from '@angular/core';

declare var _: any;

@Pipe({
    name: 'astSelect'
})

export class AstSelectPipe implements PipeTransform {

    transform(array: any[], query: string, queryTwo: number, conf: any[]): any {
        if (query) {
            let l = _.filter(array, (row: { [x: string]: string; }) => (row[conf[0].name]).toLowerCase().indexOf(query.toLowerCase()) > -1);
            if (l != '') {
                return _.slice(l, 0, queryTwo);
            }
            return;
        }
        return _.slice(array, 0, 100);
    }

}
