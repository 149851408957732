import { Pipe, PipeTransform } from '@angular/core';
import { ProductAndRatePojo } from 'src/app/pojos/product-master.pojo';

declare var _: any;

@Pipe({
    name: 'productMaster'
})

export class ProductMasterPipe implements PipeTransform {

    transform(array: ProductAndRatePojo[], query: string): any {
        if (query) {
            if (_.filter(array, (row: { productMasterPojo: { productId: string; }; }) => row.productMasterPojo.productId == query) != '') {
                return _.filter(array, (row: { productMasterPojo: { productId: string; }; }) => row.productMasterPojo.productId == query);
            } else if (_.filter(array, (row: { productMasterPojo: { productCode: string; }; }) => row.productMasterPojo.productCode.toLowerCase().indexOf(query.toLowerCase()) > -1) != '') {
                return _.filter(array, (row: { productMasterPojo: { productCode: string; }; }) => row.productMasterPojo.productCode.toLowerCase().indexOf(query.toLowerCase()) > -1);
            } else if (_.filter(array, (row: { productMasterPojo: { productName: string; }; }) => row.productMasterPojo.productName.toLowerCase().indexOf(query.toLowerCase()) == 0) != '') {
                return _.filter(array, (row: { productMasterPojo: { productName: string; }; }) => row.productMasterPojo.productName.toLowerCase().indexOf(query.toLowerCase()) == 0);
            } else if (_.filter(array, (row: { productMasterPojo: { productNameTamil: string; }; }) => row.productMasterPojo.productNameTamil.toLowerCase().indexOf(query.toLowerCase()) == 0) != '') {
                return _.filter(array, (row: { productMasterPojo: { productNameTamil: string; }; }) => row.productMasterPojo.productNameTamil.toLowerCase().indexOf(query.toLowerCase()) == 0);
            } else if (_.filter(array, (row: { barcodePojo: { barCode: number; }; }) => row.barcodePojo.barCode > -1) != '') {
                return _.filter(array, (row: { barcodePojo: { barCode: number; }; }) => row.barcodePojo.barCode > -1);
            }
            else {
                return false;
            }
        }
        return array;
    }

}

@Pipe({
    name: 'productMasterAndRate'
})

export class ProductMasterAndRatePipe implements PipeTransform {

    transform(array: any[], query: string): any {
        if (query) {
            if (_.filter(array, (row: { productId: string; }) => row.productId == query) != '') {
                return _.filter(array, (row: { productId: string; }) => row.productId == query);
            } else if (_.filter(array, (row: { barcodePojo: { barcode: string; }; }) => row.barcodePojo.barcode.toLowerCase().indexOf(query.toLowerCase()) > -1) != '') {
                return _.filter(array, (row: { barcodePojo: { barcode: string; }; }) => row.barcodePojo.barcode.toLowerCase().indexOf(query.toLowerCase()) > -1);
            } else if (_.filter(array, (row: { productMasterPojo: { productName: string; }; }) => row.productMasterPojo.productName.toLowerCase().indexOf(query.toLowerCase()) > -1) != '') {
                return _.filter(array, (row: { productMasterPojo: { productName: string; }; }) => row.productMasterPojo.productName.toLowerCase().indexOf(query.toLowerCase()) > -1);
            }else {
                return [];
            }
        }
        return array;
    }

}
