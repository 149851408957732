import { Pipe, PipeTransform } from '@angular/core';

declare var _: any;

@Pipe({
    name: 'incentivemasterpipe'
})


export class IncentivemasterpipePipe implements PipeTransform {

    transform(array: any[], query: string): any {
        if (query) {
            if (_.filter(array, (row: { incentiveName: string; }) => row.incentiveName == query) != '') {
                return _.filter(array, (row: { incentiveName: string; }) => row.incentiveName == query);
            } else {
                return false;
            }
        }
        return array;
    }

}

@Pipe({
    name: 'incentivepipe'
})

export class IncentivePipe implements PipeTransform {

    transform(array: any[], query: string, queryTwo: number): any {
        if (query) {
            let l = _.filter(array, (row: { incentiveName: string; }) => row.incentiveName.toLowerCase().indexOf(query.toLowerCase()) > -1);
            if (l != '') {
                return _.slice(l, 0, queryTwo);
            }
            return;
        }
        return _.slice(array, 0, 100);
    }

}

