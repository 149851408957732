import { Pipe, PipeTransform } from '@angular/core';

declare var _: any;

@Pipe({
  name: 'genericUnitMaster'
})

export class GenericUnitMasterPipe implements PipeTransform {

  transform(array: any[], query: string): any {
    if (query) {
      if (_.filter(array, (row: { genericUnitId: string; }) => row.genericUnitId == query) != '') {
        return _.filter(array, (row: { genericUnitId: string; }) => row.genericUnitId == query);
      } else if (_.filter(array, (row: { genericUnitName: string; }) => row.genericUnitName.toLowerCase().indexOf(query.toLowerCase()) > -1) != '') {
        return _.filter(array, (row: { genericUnitName: string; }) => row.genericUnitName.toLowerCase().indexOf(query.toLowerCase()) > -1);
      } else {
        return false;
      }
    }
    return array;
  }

}

@Pipe({
  name: 'genericUnitName'
})

export class GenericUnitNamePipe implements PipeTransform {

  transform(array: any[], query: string, queryTwo: number): any {
    if (query) {
      let l = _.filter(array, (row: { genericUnitName: string; }) => row.genericUnitName.toLowerCase().indexOf(query.toLowerCase()) > -1);
      if (l != '') {
        return _.slice(l, 0, queryTwo);
      }
      return;
    }
    return _.slice(array, 0, 100);
  }

}
